import { HttpErrorResponse } from '@angular/common/http';
import { Application, ApplicationStepWithoutDeclined, EmailContent } from '@mkp/application/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const applicationApiActions = createActionGroup({
  source: 'Application Api',
  events: {
    'Route Application Loaded Success': props<{ application: Application }>(),
    'Route Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Applications Loaded Success': props<{ applications: Application[] }>(),
    'Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Refresh Success': props<{ application: Application }>(),
    'Application Refresh Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Refresh Not Found': props<{ applicationId: string }>(),
    'More Applications Loaded Success': props<{ applications: Application[] }>(),
    'More Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'One More Application Loaded Success': props<{ applications: Application[] }>(),
    'One More Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Deleted Success': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Application Deleted Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationFullName: string;
    }>(),
    'Application Deleted Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
    'Email For Deletion Sent Failure Application Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
    'Application Declined With Email Success': props<{
      application: Application;
      emailContent: EmailContent;
    }>(),
    'Application Declined Without Email Success': props<{
      application: Application;
    }>(),
    'Email For Declination Sent Success': props<{
      application: Application;
    }>(),
    'Email For Declination Sent Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Email For Deletion Sent Success': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Email For Deletion Sent Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationFullName: string;
    }>(),
    'Application Status Change Success': props<{ application: Application }>(),
    'Application Status Change Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
      statusId: string;
    }>(),
    'Application Status Change Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
    'Applications Presence Confirmed': props<{
      step: ApplicationStepWithoutDeclined;
      limit: number;
      offset: number;
    }>(),
    'Applications Non Presence Confirmed': emptyProps(),
    'Applications Presence Check Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Status Already Changed': props<{
      applicationId: string;
      statusId: string;
    }>(),
    'Application Reload For Status Already Changed Success': props<{
      application: Application;
      statusId: string;
    }>(),
    'Application Reload For Status Already Changed Failure': props<{
      errorMessage: HttpErrorResponse;
    }>(),
  },
});
