import { Application, ApplicationStatus } from '@mkp/application/models';
import {
  applicationApiActions,
  applicationPageActions,
  applicationStatusApiActions,
} from '@mkp/application/state/actions';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

interface State extends EntityState<Application> {
  applicationStatuses: ApplicationStatus[];
  fetchState: FetchState;
}

const adapter: EntityAdapter<Application> = createEntityAdapter<Application>();

const initialState: State = adapter.getInitialState({
  applicationStatuses: [],
  fetchState: LoadingState.INIT,
});

const reducer = createReducer(
  initialState,
  on(
    applicationPageActions.userClickedOnStatusInActionButton,
    applicationPageActions.userClickedOnStatusInDropdown,
    (state) => ({
      ...state,
      fetchState: LoadingState.LOADING,
    })
  ),
  on(applicationPageActions.opened, applicationPageActions.tabChanged, (state) =>
    adapter.removeAll({
      ...state,
      fetchState: LoadingState.LOADING,
    })
  ),
  on(applicationApiActions.applicationsLoadedSuccess, (state, { applications }) =>
    adapter.setAll(applications, {
      ...state,
      fetchState: LoadingState.LOADED,
    })
  ),
  on(
    applicationApiActions.moreApplicationsLoadedSuccess,
    applicationApiActions.oneMoreApplicationLoadedSuccess,
    (state, { applications }) =>
      adapter.upsertMany(applications, {
        ...state,
        fetchState: LoadingState.LOADED,
      })
  ),
  on(applicationApiActions.routeApplicationLoadedSuccess, (state, { application }) =>
    adapter.upsertOne(application, { ...state, fetchState: LoadingState.LOADED })
  ),
  on(
    applicationApiActions.applicationsPresenceConfirmed,
    applicationApiActions.applicationsNonPresenceConfirmed,
    (state) => ({
      ...state,
      fetchState: LoadingState.LOADED,
    })
  ),
  on(
    applicationStatusApiActions.applicationStatusesLoadedSuccess,
    (state, { applicationStatuses }) => ({
      ...state,
      applicationStatuses,
    })
  ),
  on(
    applicationApiActions.applicationDeletedFailure,
    applicationApiActions.applicationsLoadedFailure,
    applicationApiActions.routeApplicationLoadedFailure,
    applicationApiActions.moreApplicationsLoadedFailure,
    applicationApiActions.oneMoreApplicationLoadedFailure,
    applicationApiActions.applicationRefreshFailure,
    applicationApiActions.applicationStatusChangeFailure,
    applicationApiActions.oneMoreApplicationLoadedFailure,
    applicationApiActions.applicationsPresenceCheckFailure,
    applicationStatusApiActions.applicationStatusesLoadedFailure,
    applicationApiActions.applicationReloadForStatusAlreadyChangedFailure,
    (state, { errorMessage }) => ({
      ...state,
      fetchState: errorMessage,
    })
  ),
  on(
    applicationApiActions.applicationDeletedSuccess,
    applicationApiActions.applicationDeletedNotFound,
    applicationApiActions.emailForDeletionSentFailureApplicationNotFound,
    applicationApiActions.applicationStatusChangeNotFound,
    applicationApiActions.applicationRefreshNotFound,
    (state, { applicationId }) => adapter.removeOne(applicationId, { ...state })
  ),

  on(
    applicationApiActions.applicationStatusChangeSuccess,
    applicationApiActions.applicationRefreshSuccess,
    applicationApiActions.applicationReloadForStatusAlreadyChangedSuccess,
    applicationApiActions.applicationDeclinedWithEmailSuccess,
    applicationApiActions.applicationDeclinedWithoutEmailSuccess,
    (state, { application }) =>
      adapter.updateOne(
        { id: application.id, changes: application },
        { ...state, fetchState: LoadingState.LOADED }
      )
  )
);
const feature = createFeature({
  name: 'application',
  reducer,
});

const { selectAll } = adapter.getSelectors(feature.selectApplicationState);

export const applicationFeature = { ...feature, selectAll };
