import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { selectVacancyIdFromRoute } from '@app/features/vacancy/store/selectors/vacancy.selectors';
import {
  applicationByStatusesMap,
  ApplicationResource,
  ApplicationStatusResource,
  FetchApplicationPayload,
  mapApplicationDtoToModel,
} from '@mkp/application/data-access';
import { ApplicationStepWithoutDeclined } from '@mkp/application/models';
import {
  applicationApiActions,
  applicationExistGuardActions,
  applicationPageActions,
  applicationStatusApiActions,
  applicationStatusesGuardActions,
} from '@mkp/application/state/actions';
import { documentApiActions } from '@mkp/document/state/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
} from 'rxjs';
import { selectApplicationStatuses } from './application.selectors';

export const loadRouteApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationExistGuardActions.canActivate),
      map(({ id }) => id),
      filter(Boolean),
      exhaustMap((id) =>
        applicationResource.getById(id).pipe(
          map(mapApplicationDtoToModel),
          map((application) =>
            applicationApiActions.routeApplicationLoadedSuccess({ application })
          ),
          catchError((error) =>
            of(applicationApiActions.routeApplicationLoadedFailure({ errorMessage: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const checkApplicationsPresence = createEffect(
  (
    actions$ = inject(Actions),
    applicationResource = inject(ApplicationResource),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(applicationPageActions.opened),
      concatLatestFrom(() => store.select(selectVacancyIdFromRoute)),
      map(([{ step, offset, limit }, vacancyId]) => ({
        step,
        offset,
        limit,
        vacancyId,
      })),
      exhaustMap(({ step, offset, limit, vacancyId }) =>
        applicationResource.checkApplicationsPresence(vacancyId!).pipe(
          map((applicationsExist) =>
            applicationsExist
              ? applicationApiActions.applicationsPresenceConfirmed({ step, offset, limit })
              : applicationApiActions.applicationsNonPresenceConfirmed()
          ),
          catchError((error) => of(applicationApiActions.applicationsPresenceCheckFailure(error)))
        )
      )
    );
  },
  { functional: true }
);
export const refreshApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.userSelectApplication,
        applicationPageActions.selectApplicationOnChange
      ),
      map(({ applicationId }) => applicationId),
      filter(Boolean),
      concatMap((applicationId) => {
        return applicationResource.getById(applicationId).pipe(
          map(mapApplicationDtoToModel),
          map((application) => applicationApiActions.applicationRefreshSuccess({ application })),
          catchError((errorMessage) => {
            if (errorMessage.status === 404) {
              return of(applicationApiActions.applicationRefreshNotFound({ applicationId }));
            }
            return of(
              applicationApiActions.applicationRefreshFailure({
                errorMessage,
              })
            );
          })
        );
      })
    );
  },
  { functional: true }
);

export const loadApplications = createEffect(
  (
    actions$ = inject(Actions),
    applicationResource = inject(ApplicationResource),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.tabChanged,
        applicationApiActions.applicationsPresenceConfirmed
      ),
      concatLatestFrom(() => [
        store.select(selectApplicationStatuses),
        store.select(selectVacancyIdFromRoute),
      ]),
      map(([{ step, limit, offset }, statuses, vacancyId]) => ({
        applicationStatusId: applicationByStatusesMap(statuses)[step].statusIds,
        vacancyId,
        offset,
        limit,
        step,
      })),
      filter(isFetchApplicationPayload),
      switchMap(({ applicationStatusId, vacancyId, offset, limit, step }) =>
        applicationResource
          .fetchApplicationsByStatus({
            applicationStatusId,
            vacancyId,
            offset,
            limit,
            step,
          })
          .pipe(
            map((applications) =>
              applicationApiActions.applicationsLoadedSuccess({ applications })
            ),
            catchError((error) =>
              of(applicationApiActions.applicationsLoadedFailure({ errorMessage: error }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const loadApplicationStatuses = createEffect(
  (actions$ = inject(Actions), applicationStatusResource = inject(ApplicationStatusResource)) => {
    const store = inject(Store);
    return actions$.pipe(
      ofType(applicationStatusesGuardActions.canActivate),
      concatLatestFrom(() => store.select(selectApplicationStatuses)),
      filter(([, statuses]) => !statuses.length),
      exhaustMap(() =>
        applicationStatusResource.fetchApplicationStatuses().pipe(
          map((applicationStatuses) =>
            applicationStatusApiActions.applicationStatusesLoadedSuccess({ applicationStatuses })
          ),
          catchError((error) =>
            of(
              applicationStatusApiActions.applicationStatusesLoadedFailure({
                errorMessage: error,
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadMoreApplications = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.loadMoreApplications,
        applicationPageActions.loadMoreApplicationsToFindRouteApplication
      ),
      loadOneOrMoreApplications(
        applicationApiActions.moreApplicationsLoadedSuccess,
        applicationApiActions.moreApplicationsLoadedFailure
      )
    );
  },
  { functional: true }
);

export const loadOneMoreApplication = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(applicationPageActions.loadOneMoreApplication),
      loadOneOrMoreApplications(
        applicationApiActions.oneMoreApplicationLoadedSuccess,
        applicationApiActions.oneMoreApplicationLoadedFailure
      )
    );
  },
  { functional: true }
);

export const deleteApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(documentApiActions.documentsDeletedSuccess),
      exhaustMap(({ applicationId, applicationFullName }) =>
        applicationResource.deleteApplication(applicationId).pipe(
          map(() =>
            applicationApiActions.applicationDeletedSuccess({
              applicationId,
              applicationFullName,
            })
          ),
          catchError((errorMessage) => {
            if (errorMessage.status === 404) {
              return of(
                applicationApiActions.applicationDeletedNotFound({
                  errorMessage,
                  applicationId,
                })
              );
            }
            return of(
              applicationApiActions.applicationDeletedFailure({ errorMessage, applicationFullName })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

export const sendDeclinedEmailBeforeDeletion = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationPageActions.deleteApplicationWithEmail),
      exhaustMap(({ emailContent, applicationId, applicationFullName }) =>
        applicationResource.sendDeclinationMail(applicationId, emailContent).pipe(
          map(() =>
            applicationApiActions.emailForDeletionSentSuccess({
              applicationFullName,
              applicationId,
            })
          ),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              return of(
                applicationApiActions.emailForDeletionSentFailureApplicationNotFound({
                  errorMessage: err,
                  applicationId,
                })
              );
            }
            return of(
              applicationApiActions.emailForDeletionSentFailure({
                errorMessage: err,
                applicationFullName,
              })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

export const updateApplicationStatus = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.userClickedOnStatusInActionButton,
        applicationPageActions.userClickedOnStatusInDropdown
      ),
      exhaustMap(({ applicationId, statusId, _version }) =>
        applicationResource.updateApplicationStatus(applicationId, statusId, _version).pipe(
          map((application) =>
            applicationApiActions.applicationStatusChangeSuccess({ application })
          ),
          catchError((error: HttpErrorResponse) =>
            getStatusChangeErrorAction(error, applicationId, _version)
          )
        )
      )
    );
  },
  { functional: true }
);

export const declineApplicationWithEmail = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationPageActions.declineApplicationWithEmail),
      exhaustMap(({ emailContent, applicationId, declineStatusId, _version }) =>
        applicationResource.updateApplicationStatus(applicationId, declineStatusId, _version).pipe(
          map((application) =>
            applicationApiActions.applicationDeclinedWithEmailSuccess({
              application,
              emailContent,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            getStatusChangeErrorAction(error, applicationId, declineStatusId)
          )
        )
      )
    );
  },
  { functional: true }
);

export const declineApplicationWithoutEmail = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationPageActions.declineApplicationWithoutEmail),
      exhaustMap(({ applicationId, declineStatusId, _version }) =>
        applicationResource.updateApplicationStatus(applicationId, declineStatusId, _version).pipe(
          map((application) =>
            applicationApiActions.applicationDeclinedWithoutEmailSuccess({
              application,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            getStatusChangeErrorAction(error, applicationId, declineStatusId)
          )
        )
      )
    );
  },
  { functional: true }
);

export const sendDeclinationEmail = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationApiActions.applicationDeclinedWithEmailSuccess),
      exhaustMap(({ application, emailContent }) =>
        applicationResource.sendDeclinationMail(application.id, emailContent).pipe(
          map(() => applicationApiActions.emailForDeclinationSentSuccess({ application })),
          catchError((err) =>
            of(applicationApiActions.emailForDeclinationSentFailure({ errorMessage: err }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const reloadApplicationForStatusAlreadyChanged = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationApiActions.applicationStatusAlreadyChanged),
      exhaustMap(({ applicationId, statusId }) =>
        applicationResource.getById(applicationId).pipe(
          map(mapApplicationDtoToModel),
          map((application) =>
            applicationApiActions.applicationReloadForStatusAlreadyChangedSuccess({
              application,
              statusId, //for error message
            })
          ),
          catchError((error) =>
            of(
              applicationApiActions.applicationReloadForStatusAlreadyChangedFailure({
                errorMessage: error,
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

const isFetchApplicationPayload = (payload: {
  applicationStatusId: string | string[];
  vacancyId: string | undefined;
  offset: number;
  limit: number;
  step?: number;
}): payload is FetchApplicationPayload =>
  Boolean(payload.applicationStatusId) && Boolean(payload.vacancyId);

type LoadOneOrMoreApplicationsSuccessActionCreator =
  | typeof applicationApiActions.oneMoreApplicationLoadedSuccess
  | typeof applicationApiActions.moreApplicationsLoadedSuccess;
type LoadOneOrMoreApplicationsFailureActionCreator =
  | typeof applicationApiActions.oneMoreApplicationLoadedFailure
  | typeof applicationApiActions.moreApplicationsLoadedFailure;
type LoadOneOrMoreApplicationsAction = ReturnType<
  LoadOneOrMoreApplicationsSuccessActionCreator | LoadOneOrMoreApplicationsFailureActionCreator
>;

const loadOneOrMoreApplications = (
  successAction: LoadOneOrMoreApplicationsSuccessActionCreator,
  failureAction: LoadOneOrMoreApplicationsFailureActionCreator,
  store = inject(Store),
  applicationResource = inject(ApplicationResource)
): OperatorFunction<
  {
    step: ApplicationStepWithoutDeclined;
    limit: number;
    offset: number;
  },
  LoadOneOrMoreApplicationsAction
> => {
  return (source) =>
    source.pipe(
      concatLatestFrom(() => [
        store.select(selectApplicationStatuses),
        store.select(selectVacancyIdFromRoute),
      ]),
      map(([{ step, limit, offset }, statuses, vacancyId]) => ({
        applicationStatusId: applicationByStatusesMap(statuses)[step].statusIds,
        vacancyId,
        offset,
        limit,
        step,
      })),
      filter(isFetchApplicationPayload),
      switchMap(({ applicationStatusId, vacancyId, offset, limit, step }) =>
        applicationResource
          .fetchApplicationsByStatus({
            applicationStatusId,
            vacancyId,
            offset,
            limit,
            step,
          })
          .pipe(
            map((applications) => successAction({ applications })),
            catchError((error) => of(failureAction({ errorMessage: error })))
          )
      )
    );
};

type UpdateStatusErrorAction = ReturnType<
  | typeof applicationApiActions.applicationStatusAlreadyChanged
  | typeof applicationApiActions.applicationStatusChangeNotFound
  | typeof applicationApiActions.applicationStatusChangeFailure
>;
const getStatusChangeErrorAction = (
  error: HttpErrorResponse,
  applicationId: string,
  statusId: string
): Observable<UpdateStatusErrorAction> => {
  const action =
    error.status === 409
      ? applicationApiActions.applicationStatusAlreadyChanged({ applicationId, statusId })
      : error.status === 404
        ? applicationApiActions.applicationStatusChangeNotFound({
            errorMessage: error,
            applicationId,
          })
        : applicationApiActions.applicationStatusChangeFailure({
            errorMessage: error,
            applicationId,
            statusId,
          });

  return of(action);
};
